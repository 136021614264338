import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Router from './Router';
import 'tailwindcss/tailwind.css';
// import '@arco-design/web-react/dist/css/arco.css'
import 'hover.css/css/hover-min.css'
import '@/global.scss';
import { Providers } from '@/providers';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    // <StrictMode>
    <HashRouter>
      <Providers>
        <Router />
      </Providers>
    </HashRouter>
    // </StrictMode>
  );
}
