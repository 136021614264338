import request from '@/utils/request';

export function commonFetcher(url: string) {
  return request({
    url,
    method: 'get',
  }).then((res) => res.data);
}

export function web3_nonce(data) {
  return request({
    url: `/account/web3/web3_nonce`,
    method: 'post',
    data: data
  }).then((res) => res.data);
}
export function web3_challenge(data) {
  return request({
    url: `/account/web3/web3_challenge`,
    method: 'post',
    data: data
  }).then((res) => res.data);
}

export function getGoldLeaves() {
  return request({
    url: `/GoldLeaf/me`,
    method: 'get'
  }).then((res) => res.data);
}
export function sync(userAddress: string, petNFTAddress: string): Promise<any> {
  return request({
    url: `/pet/sync/${userAddress}/${petNFTAddress}`,
    method: 'get'
  }).then((res) => res.data);
}



