import styles from './index.module.scss'
import cx from 'classnames'

function Home() {

  if (window.isMobile) {
    return <div className={styles.mobilePage}>
      <img className={styles.ring} src="/images/ring.webp" alt="" />
      <div className={styles.middle}>
        <div className={styles.textWrap}>
          <div className={styles.row} data-aos="fade-up" data-aos-delay="0">The Modular</div>
          <div className={styles.row} data-aos="fade-up" data-aos-delay="300"><b>AI-Native Data</b></div>
          <div className={styles.row} data-aos="fade-up" data-aos-delay="600">Pre-Processing</div>
          <div className={styles.row} data-aos="fade-up" data-aos-delay="600">Layer</div>
          <div className={styles.row2} data-aos="fade-up" data-aos-delay="900">
            <div className={styles.dot}></div>
            <div className={styles.line}></div>
            <span>Cook data for AI and get paid</span>
          </div>
        </div>
      </div>
      <div className={styles.btnWrap} data-aos="fade-up" data-aos-delay="1200">
        <a href="https://xdata.din.lol/" className={cx(styles.btn, 'hvr-float-shadow')}>Get Started &gt;</a>
      </div>
    </div>
  }
  return <div className={styles.page}>
    <div className={styles.animateWrap}>
      <div className={styles.inner} >
        <img className={styles.bg} src="/images/homeBg.svg" alt="" />
        <img className={styles.sCircle} src="/images/sCircle.png" alt="" />
        <img className={styles.bCircle} src="/images/bCircle.png" alt="" />
      </div>
    </div>
    <div className={styles.textWrap}>
      <div className={styles.row1} data-aos="fade-up" data-aos-delay="0"><i>The</i></div>
      <div className={styles.row2} data-aos="fade-up" data-aos-delay="300"><i>Modular</i> AI-Native Data</div>
      <div className={styles.row3} data-aos="fade-up" data-aos-delay="600">Pre-Processing Layer</div>
      <div className={styles.row4} data-aos="fade-up" data-aos-delay="900">
        <div className={styles.dot}></div>
        <span>Cook data for AI and get paid</span>
      </div>
      <div className={styles.btnWrap} data-aos="fade-up" data-aos-delay="1200">
        <a href="https://xdata.din.lol/" className={cx(styles.btn, 'hvr-float-shadow')}>Get Started &gt;</a>
      </div>
    </div>
  </div>
}

export default Home;
