'use client';
// import { ConfigProvider } from '@arco-design/web-react'
// import enUS from '@arco-design/web-react/es/locale/en-US'
import * as React from 'react';
import { SWRConfig } from 'swr';
import { commonFetcher, web3_challenge, web3_nonce } from '@/api';

export function Providers({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => setMounted(true), []);
  return (
    <SWRConfig
      value={{
        fetcher: commonFetcher,
        shouldRetryOnError: false,
        revalidateOnFocus: false,
      }}
    >

      {mounted && children}
    </SWRConfig>
  );
}
