import { useState } from 'react';
import styles from './index.module.scss'
import cx from 'classnames'

function DinNode() {
  const [activeNav, setActiveNav] = useState(1)
  const partnerList = [{
    icon: '/images/bnb.png',
    name: 'BNB chain'
  }, {
    icon: '/images/opbnb.png',
    name: 'opBNB'
  }, {
    icon: '/images/greenfield.png',
    name: 'BNB Greenfield'
  }, {
    icon: '/images/d4.png',
    name: 'Dappad'
  }, {
    icon: '/images/d5.png',
    name: 'Node Terminal'
  }, {
    icon: '/images/d6.png',
    name: 'Samurai'
  }, {
    icon: '/images/d7.png',
    name: 'Easeflow'
  }, {
    icon: '/images/d8.png',
    name: 'Rapidnode'
  }, {
    icon: '/images/d9.png',
    name: 'Infstones'
  }, {
    icon: '/images/d10.png',
    name: 'EasyNode'
  }, {
    icon: '/images/d11.png',
    name: 'NodeOps'
  }]
  return <div className={styles.page}>
    <div className={styles.bigTitle}>Product</div>
    <div className={styles.main}>
      <div className={styles.mLeft}>
        <div className={styles.tRow}>
          <span className={styles.productName}>DIN Node</span>
          <a href="https://node.din.lol/" target="_blank" className={cx(styles.btn, 'hvr-float-shadow')}>Explore &gt;</a>
        </div>
        <div className={styles.text}>The Core of DIN Ecosystem</div>
        <div className={styles.iL}>
          <div className={styles.row}>
            <img src="/images/n41.png" alt="" />
            <span>The Bridge of Data {window.isMobile && <br />}Pre-Processing Procedure</span>
          </div>
          <div className={styles.row}>
            <img src="/images/n42.png" alt="" />
            <span>Edge Computing Advantages</span>
          </div>
          <div className={styles.row}>
            <img src="/images/n43.png" alt="" />
            <span>Local Small Language Model Loading</span>
          </div>
          <div className={styles.row}>
            <img src="/images/n44.png" alt="" />
            <span>The Power Station of Reward Conversion</span>
          </div>
        </div>
      </div>
      {
        window.isMobile &&
        <div className={styles.mIconWrap}>
          <img className={styles.mIcon} src="/images/mDIcon.png" alt="" />
        </div>
      }
      <div className={styles.mRight}>
        {
          !window.isMobile && <img className={styles.n3b} src="/images/n4b.png" alt="" />
        }
        <div className={styles.mRR}>
          <div className={styles.partners}>
            <div className={styles.title}>Partners</div>
            <div className={styles.list}>
              {
                partnerList.map(v => <div className={styles.item}>
                  <img src={v.icon} alt="" />
                  <span>{v.name}</span>
                </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div >
}

export default DinNode;
