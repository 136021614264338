import axios from 'axios';



// create an axios instance
const service = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && !config.headers.Authorization) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      // eslint-disable-next-line no-param-reassign
      // config.headers['X-App-Channel'] = `DIN`;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response;
    if (response.status >= 500) {
      return Promise.reject(new Error(res.data.message || 'Error'));
    }
    return res;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('token')
    }
    return Promise.reject(error);
  },
);


export default service;
