import styles from './index.module.scss'
import cx from 'classnames'

function Career() {
  return <div className={styles.page}>
    <div className={styles.bTitle}>Career</div>
    <div className={styles.List}>
      <a href='https://dinlol.notion.site/Web3Go-Ambassador-Program-Assemble-GODINs-f79feef2d685437e88e0c3020e97f8c4' target='_blank' className={styles.item}>
        <span>Ambassador Program</span>
        <div className={cx(styles.btn, 'hvr-shrink')}>
          Apply
        </div>
      </a>
      <a href='https://dinlol.notion.site/Web3Go-Team-Hiring-a081e0c2ad4147c4b17cf2a8a89e10f9' target='_blank' className={styles.item}>
        <span>DIN Team</span>
        <div className={cx(styles.btn, 'hvr-shrink')}>
          Apply
        </div>
      </a>
    </div>
  </div>
}

export default Career;
